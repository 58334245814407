<template>
  <div>
    <div class="flex-align" style="gap:8px">
      <input
          :style="wrapperStyle"
          class="input"
          ref="input"
          type="email"
          v-model="inputValue"
          :maxlength="200"
          @keyup.enter="clickValid"
          placeholder="example@gmail.com"
          :disabled="disabled"/>
        <button v-if="!valid" :disabled="inputDisabled" 
                class="button is-primary" @click="clickValid">인증</button>
        <button v-else :disabled="success"
                class="button is-primary" @click="clickResend">재전송</button>
    </div>
    <div class="msg-error" v-if="error">
      <img src="/static/icon/ic_warning_circle_error.svg">
      <div class="size-12 highlight">{{ errorMsg }}</div>
    </div>
    <div v-if="valid" class="flex-align" style="margin-top:8px;gap: 8px">
      <div class="position-relative" style="flex:1">
        <input maxlength="6" placeholder="인증번호 6자리" type="text" v-model="validNum"
               class="input" :class="{'success': success, 'error': errorValid}" :readonly="success">
            <div class="remain highlight" v-if="remainNum > -1">{{ remain }}</div>
      </div>
      <button class="button is-primary" :disabled="success" @click="confirm">확인</button>
    </div>
    <div class="msg-success" v-if="success">
      <i class="material-icons">check</i>
      <div class="size-12">인증이 완료되었습니다.</div>
    </div>
    <div class="msg-error" v-else-if="errorValid">
      <img src="/static/icon/ic_exclamation.svg">
      <div class="size-12 highlight">{{ errorValidMsg }}</div>
    </div>
  </div>
</template>
<script>
  import {numToTime} from '@/utils/helper/format-helper'
  import FormMixin from "@/mixins/FormMixin";
  export default {
    name: "LpEmail",
    mixins: [
      FormMixin
    ],
    props: {
      value: {
        type: String
      },
      disabled: {
        type: Boolean,
        required: false
      },
      placeholder: {
        type: String,
        default: ''
      },
      width: {
        type: String
      }
    },
    data() {
      return {
        inputValue: '',
        validNum: '',
        valid: false,
        success: false,
        error: false,
        errorMsg: '필수입력 사항입니다.',
        errorValid: false,
        errorValidMsg: '필수입력 사항입니다.',
        remainNum: -1,
        itv: undefined,
        inputDisabled: false
      }
    },
    computed: {
      remain() {
        return numToTime(this.remainNum).substring(2);
      },
      wrapperStyle() {
        let style = {};
        if(this.width) {
          style.width = this.width;
        }
        return style;
      }
    },
    methods: {
      clickValid() {
         // 인증 관련 로직 실행
         if(this.inputValue === '') {
          this.error = true;
          this.errorMsg = '필수입력 사항입니다.';
        } else if(/[0-9a-zA-Z][_0-9a-zA-Z-]*@[_0-9a-zA-Z-]+(\.[_0-9a-zA-Z-]+){1,2}$/.test(this.inputValue) === false) {
          this.error = true;
          this.errorMsg = '이메일 주소를 정확히 입력해주세요';
        } else {
          this.$store.commit('setLoading', true);
          this.$axios.post('auth/user/check/email', {email: this.inputValue}).then(res => {
            if(res.data.result === 'ok') {
              // 인증 처리
              this.$axios.post('public/email/verify/send', {email: this.inputValue}).then(() => {
                this.$store.commit('setLoading', false);
                this.toast('인증번호가 발송되었습니다.');
                this.inputDisabled = true;
                this.error = false;
                this.valid = true;
                this.remainNum = 300;
                this.itv = setInterval(this.intervalRemain, 1000);
              }).catch(() => {
                this.$store.commit('setLoading', false);
                this.toast('인증번호 발송에 실패했습니다.');
              })
            } else {
              this.$store.commit('setLoading', false);
              this.errorMsg = `이미 가입된 ${res.data.login_type}${res.data.login_type === 'SNS' ? ' ' + '(' + res.data.sns + ')' : ''} 계정이 있습니다.`;
              this.error = true;
            }
          }).catch(() => {
            this.$store.commit('setLoading', false);
          });
        }
      },
      clickResend() {
        if (!this.success) {
          this.$store.commit('setLoading', true);
          this.$axios.post('public/email/verify/send', {email: this.inputValue}).then(() => {
            this.$store.commit('setLoading', false);
            this.toast('인증번호가 메일로 발송되었습니다.');
            clearInterval(this.itv);
            this.inputDisabled = true;
            this.error = false;
            this.valid = true;
            this.remainNum = 300;
            this.itv = setInterval(this.intervalRemain, 1000);
          }).catch(() => {
            this.$store.commit('setLoading', false);
            this.toast('인증번호 발송에 실패했습니다.');
          })
        }
      },
      confirm() {
        let params = {
          email: this.inputValue,
          verify_num: this.validNum
        }
        this.$axios.post('public/email/verify/confirm', params).then(() => {
          if (this.remainNum > 0) {
            this.errorValid = false;
            this.success = true;
            this.remainNum = -1;
            clearInterval(this.itv);
            this.$emit('update:value', this.inputValue);
            this.$emit('auth',this.inputValue);
          } else {
            this.errorValidMsg = '인증번호가 만료되었습니다.';
            this.errorValid = true;
            this.inputDisabled = false;
          }
        }).catch(() => {
          this.toast('인증번호가 일치하지 않습니다.');
          this.errorValidMsg = '인증번호가 일치하지 않습니다.';
          this.errorValid = true;
        })
      },
      intervalRemain() {
        this.remainNum--;
        if(this.remainNum===0) {
          clearInterval(this.itv);
          this.errorValidMsg = '인증번호가 만료되었습니다.';
          this.errorValid = true;
          this.inputDisabled = false;
        }
      },
    }
  }
</script>
<style lang="stylus" scoped>
  @import '~assets/css/lp_main'
  input
    height 48px
    flex 1

  .error
    border 1px solid $error !important

  .success
    border 1px solid $success !important


  .button
    width 80px
    height 48px
    font-size 16px
  .msg-error
  .msg-success
    display flex
    align-items center
    margin-top 8px
    gap 0 4px

  .msg-success
    color $success
    i
      font-size 12px

  .highlight
    color #FF3B3B

  .remain
    position absolute
    top calc(50% - 12px)
    right 20px
    font-size 15px

  .button:disabled
    background #ddd
    border 1px solid #ddd
    cursor default
  .button:disabled:hover
    background #ddd
    border 1px solid #ddd
</style>
